import { auth } from "../firebase";
const inferenceUrl = window.config.inferenceEndpoint


const getRefreshedTokenId = async () => {
    return new Promise(async (resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            unsubscribe();
            try {
                const refreshedToken = await user.getIdToken(true);
                resolve(refreshedToken);
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    });
};

export const sendInferenceRequest = async (variety_code, crop_name, lowercase_crop_variety_name, weight, machine_code, image, selectedOptions, 
    discolor_quality, chalky_quality, discolor_area, chalky_area) => {
    try {
        let formData = new FormData();
        formData.append("crop_variety", variety_code);
        formData.append("crop_type", crop_name);
        formData.append("crop_variety_name", lowercase_crop_variety_name);
        formData.append("machine_type", crop_name === 'wheat' ? "M2" : 'M3');
        formData.append("company_id", localStorage.getItem('company_id'));
        formData.append("weight", weight);
        formData.append('use_legacy_company', false);
        formData.append("file", image, image.name);
        // for (const key in selectedOptions) {
        //     formData.append(key, selectedOptions[key].trim());
        // }
        if (crop_name === 'rice' && machine_code === 'M3') {
            formData.append("discolor_quality", discolor_quality);
            formData.append("chalky_quality", chalky_quality);
            formData.append("discolor_area", discolor_area);
            formData.append("chalky_area", chalky_area);
        }
        let headers = new Headers();
        const token = await getRefreshedTokenId();
        headers.append("Authorization", "Bearer " + token);
        let requestOptions = {
            method: 'POST',
            headers: headers,
            body: formData,
        };
        let url = ''
        if (crop_name === 'paddy') {
            url = `${inferenceUrl}/${crop_name.toLowerCase()}/inference`
        } else if (crop_name === 'wheat') {
            url = `${inferenceUrl}/${crop_name.toLowerCase()}/inference`
        } else if (crop_name === 'rice') {
            url = `${inferenceUrl}/scanner/${crop_name.toLowerCase()}/inference`
        }
        const res = await fetch(url, requestOptions)
        // console.log(res);
        // const data = await res.json()
        return res
    } catch (error) {
        console.log(error);
        return error
    }
}

// const getUrl = (crop_name, machine_code) => {
//     console.log(crop_name, machine_code);
//     if (crop_name === 'Rice' && machine_code === 'M3') {
//         return `${inferenceUrl}/scanner/${crop_name.toLowerCase()}/inference`
//     } else if (crop_name === 'Paddy' && machine_code === 'M4') {
//         return `${inferenceUrl}/${crop_name.toLowerCase()}/360/easy/inference`
//     } else if (crop_name === 'Wheat' && machine_code === 'M4') {
//         return `${inferenceUrl}/${crop_name.toLowerCase()}/360/easy/inference`
//     } else if (crop_name === 'Wheat' || crop_name === 'Paddy') {
//         return `${inferenceUrl}/${crop_name.toLowerCase()}/inference`
//     }
// }