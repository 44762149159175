import React, { useEffect, useState } from 'react'
import scanLoadingGif from '../../assests/scan_loading.gif'
import circular_loading_gif from '../../assests/circular_loading_gif.gif'
import { Button, CircularProgress, Modal, TextField } from '@mui/material';

function ProcessingForm({
    isProcessing,
    isCvInferenceResponce,
    getUserMetaData,
    setUpdatedUserMetaData,
    isMetaDataServerResponce,
    setIsProcessing,
    onCallInferenceServer,
    onCancleClick
}) {
    const TYPE_ARRAY = 1
    const TYPE_STRING = 2
    const [formValues, setFormValues] = useState({});
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (isProcessing === false) {
            setLoading(isProcessing)
            setFormValues({})
        }
    }, [isProcessing])

    useEffect(() => {
        const updatedMetadata = getUserMetaData.reduce((acc, item) => {
            const value = formValues[item.metadata_key] || '';
            acc[item.metadata_key] = value;
            return acc;
        }, {});
    
        setUpdatedUserMetaData(updatedMetadata);
    }, [formValues, getUserMetaData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const renderFormFields = () => {
        return getUserMetaData.map((item, index) => {
            if (item.metadata_type === TYPE_STRING) {
                // Render an input field for metadata_type 2
                return (
                    <div key={index}>
                        <TextField
                            id={item.metadata_key}
                            label={item.metadata_value}
                            variant="standard"
                            size="small"
                            style={{ marginTop: 5, width: '100%', color: 'white' }}
                            InputLabelProps={{
                                style: {
                                    color: 'white', // Label text color (white)
                                },
                            }}
                            InputProps={{ style: { color: 'white', borderBottom: '1px solid white' } }}
                            name={item.metadata_key}
                            value={formValues[item.metadata_key] || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                );
            } else if (item.metadata_type === TYPE_ARRAY && item.metadata_values) {
                return (
                    <div key={index}>
                        <label>{item.metadata_value}</label>
                        <select
                            name={item.metadata_key}
                            value={formValues[item.metadata_key] || ''}
                            onChange={handleInputChange}
                            style={{ marginTop: 5, width: '100%' }}
                        >
                            {item.metadata_values.map((value, valueIndex) => (
                                <option key={valueIndex} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                );
            }
            return null;
        });
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        setUpdatedUserMetaData(formValues)
        onCallInferenceServer()
    };

    const onCancel = () => {
        setLoading(false)
        setIsProcessing(false)
        setFormValues({})
        onCancleClick()
    }

    return (
        <Modal
            open={isProcessing}
        // onClose={onCancel}
        >
            <div style={style}>
                <div>
                    {isCvInferenceResponce
                        ? <div style={{ width: 250 }}>
                            <div className='d-flex justify-center g-5'><img src={circular_loading_gif} alt="Circular Loading" height={100} style={{ margin: 10, borderRadius: 5 }} /></div>
                        </div>
                        : <div className='m-10'>
                            <h4 style={{ marginBottom: 8, textAlign: 'center' }} className='APP-color'>Processing...</h4>
                            <img src={scanLoadingGif} alt="Scan Loading" height={100} width={250} style={{ borderRadius: 5, objectFit: 'contain' }} />
                            {/* <div
                                style={{
                                    position: 'absolute',
                                    top: (getUserMetaData.length !== 0) ? '8%' : '20%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: 'rgba(255, 255, 255, 0.8)',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    fontSize: 20
                                }}
                            >
                                Processing...
                            </div> */}
                            <h5 style={{ textAlign: 'center' }} className='APP-color'>Please wait as this may take <br></br> a few more seconds...</h5>
                        </div>}
                    {getUserMetaData?.length !== 0 ? <div className='APP-background p-10' style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                        {loading
                            ? <div>
                                {((isMetaDataServerResponce === 200) && loading) && <div>
                                    <div className='d-flex justify-center g-5'><img src={circular_loading_gif} alt="Circular Loading" height={100} style={{ borderRadius: 5 }} /></div>
                                    <div className='d-flex justify-end m-t-10'><Button style={{ color: 'white' }} onClick={onCancel}><h5>Cancel</h5></Button></div>
                                </div>}
                                {!(isMetaDataServerResponce === 200) && <div className='d-flex justify-center p-10'>
                                    <CircularProgress size={25} style={{ color: 'white' }} />
                                </div>}
                            </div>
                            : <div>
                                {renderFormFields()}
                                <div className='d-flex justify-end g-5 m-t-10'>
                                    <Button className='rs-width-100' style={{ color: 'white' }} variant="filledTonal" onClick={onCancel}>Cancel</Button>
                                    <Button className='rs-width-100' style={{ color: 'white' }} variant="filledTonal" onClick={handleSubmit}>Proceed</Button>
                                </div>
                            </div>}
                    </div> : <div className='d-flex justify-end g-5 m-t-10'>
                        <Button className='rs-width-100 APP-color' variant="filledTonal" onClick={onCancel}>Cancel</Button>
                        <Button
                            className='rs-width-100 APP-color'
                            variant="filledTonal"
                            disabled={loading}
                            startIcon={loading && <CircularProgress size={15} className='APP-color' />}
                            onClick={handleSubmit}>
                            Proceed
                        </Button>
                    </div>}
                </div>
            </div>
        </Modal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 10,
    backgroundColor: 'white',
    p: 4,
};

export default ProcessingForm